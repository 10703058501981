import { Languages } from '../types/lang.type';

export const toCapitalizeCase = (str: string) => {
  const words = str.toLowerCase().split(/[\s-]/);
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const toSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const toStringIfValue = (
  str: string | undefined | null | number,
): string | null | undefined =>
  str !== null && str !== undefined ? `${str}` : str;

export const toUpperSnakeCase = (str: string) => toSnakeCase(str).toUpperCase();

const accents: Record<string, string> = {
  à: 'a',
  â: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',
  î: 'i',
  ï: 'i',
  ô: 'o',
  ö: 'o',
  ù: 'u',
  û: 'u',
  ü: 'u',
  ç: 'c',
};

export const removeAccent = (str: string) =>
  str.replace(/[àâéèêëîïôöùûüç]/g, (match) => {
    if (match in accents) {
      return accents[match];
    } else {
      return '';
    }
  });

export async function digest(inputString: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(inputString);
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(36).padStart(2, '0'))
    .join('');
}

export function truncate(str: string, width: number): string {
  if (str.length > width) {
    return str.substring(0, width - 3) + '...';
  } else {
    return str;
  }
}

export const formatAmount = ({
  amount,
  currency,
  lang,
}: {
  amount: number;
    currency: 'EUR' | 'USD' | 'CHF' | 'TOK' | null;
  lang: Languages | null;
}): string => {
  return currency
    ? new Intl.NumberFormat(lang ? lang.replace('_', '-') : 'en-EN', {
        style: 'currency',
        currency: currency || 'USD',
      }).format(amount / 100)
    : (amount / 100).toString();
};
