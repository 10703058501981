import {
  FilterReduced,
  Form,
  IconType,
  Input,
  ListFlatInfo,
  Select,
  Widget,
  useMyUrl,
  useSearchOf,
  useTranslation,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { localMotoristsGql } from '../../common/gql/local-motorists.gql';
import { LocalMotorist } from '../../common/types/entities/localMotorist';
import { anonimizedEmail } from '../../common/utils/anonymise-email';
import { MotoristLine } from '../line/motorist.line';

type MotoristListProps = {
  widget?: {
    title?: string;
    icon?: IconType.Config.Type;
  };
  setSelectedMotorist?: (id: string) => void;
};

export const MotoristList = ({
  widget,
  setSelectedMotorist,
}: MotoristListProps) => {
  const { getParamsUrl, getParamsUrlString, setParamsUrl } = useMyUrl({});
  const urlMotoristId = useParams().motoristId;
  const { t } = useTranslation();
  const { list, paging, setSearch } = useSearchOf<
    LocalMotorist,
    { motoristPage: number; motoristLimit: number }
  >({
    searchParams: {
      order: 'lastName',
    },
    gql: localMotoristsGql,
    wsSubscriptions: ['localmotorist:created', 'localmotorist:updated'],
    limit: getParamsUrl.motoristLimit,
  });

  const { isLinked, left, localMotoristId } = useMemo(() => {
    // get filters params here

    return {
      isLinked: getParamsUrl.isLinked || 'all',
      left: getParamsUrl.left,
      localMotoristId: getParamsUrl.localMotoristId || undefined,
    };
  }, [getParamsUrl]);

  useEffect(() => {
    setSelectedMotorist?.(localMotoristId);
  }, [localMotoristId]);

  useEffect(() => {
    setParamsUrl({
      ...getParamsUrl,
      left: 'active',
      isLinked: isLinked || 'all',
      motoristPage: 1,
      motoristLimit: 50,
    });

    return () => {
      setParamsUrl({
        motoristPage: 1,
        motoristLimit: 50,
      });
    };
  }, []);

  useEffect(() => {
    setSearch({
      paging: {
        motoristPage: getParamsUrl.motoristPage || 1,
        motoristLimit: getParamsUrl.motoristLimit || 50,
      },
      params: { ...getParamsUrl },
    });
  }, [
    getParamsUrl.motoristPage,
    getParamsUrl.motoristLimit,
    getParamsUrl.localMotoristId,
    getParamsUrl.isLinked,
    getParamsUrl.left,
  ]);

  return (
    <Widget
      config={{
        title: widget?.title,
        backtitle: !!widget?.title,
        icon: widget?.icon,
      }}
    >
      <ListFlatInfo<any>
        config={{
          pagination: 'scroll',
          columns: [
            { key: 'component', label: t('identity'), size: '1fr' },
            { key: 'component', label: t('details'), size: 'min-content' },
          ],
          header: true,
          filter: (
            <FilterReduced
              data={{
                value: {
                  isLinked: isLinked || undefined,
                  localMotoristId,
                  left: left || false,
                },
              }}
              handleEvent={{
                submit: (submit) => {
                  setParamsUrl({ ...getParamsUrl, ...submit, motoristPage: 1 });
                },
                clear: () => {
                  setParamsUrl({
                    isLinked: 'active',
                    motoristPage: 1,
                    motoristLimit: 50,
                  });
                },
              }}
            >
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('motorist'),
                  name: 'isLinked',
                }}
                data={{ defaultValue: isLinked || 'all' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('all'),
                        value: 'all',
                      },
                      {
                        label: t('linked'),
                        value: 'linked',
                      },
                      {
                        label: t('unlinked'),
                        value: 'unlinked',
                      },
                    ],
                  }}
                />
              </Form.Item>
              <Form.Item
                config={{
                  labelLimitation: true,
                  label: t('status'),
                  name: 'left',
                }}
                data={{ defaultValue: left || 'active' }}
              >
                <Select
                  config={{ width: 'xmedium', clear: false }}
                  data={{
                    items: [
                      {
                        label: t('active'),
                        value: 'active',
                      },
                      {
                        label: t('left'),
                        value: 'left',
                      },
                    ],
                  }}
                />
              </Form.Item>
              {localMotoristId && (
                <Form.Item
                  config={{
                    labelLimitation: true,
                    label: t('selectedMotorist'),
                    name: 'selectedMotorist',
                  }}
                  data={{
                    defaultValue:
                      list && list[0]?.firstName && list[0]?.lastName
                        ? `${list[0]?.firstName} ${list[0]?.lastName}`
                        : list && list[0]?.email
                          ? list[0]?.email
                          : list
                            ? list[0]._id
                            : '-',
                  }}
                >
                  <Input
                    config={{
                      type: { name: 'text' },
                      placeholder: localMotoristId,
                      disabled: true,
                    }}
                  ></Input>
                </Form.Item>
              )}
            </FilterReduced>
          ),
        }}
        handleEvent={{
          paging: (paging) => {
            setParamsUrl({
              ...getParamsUrl,
              motoristPage: paging.page,
              motoristLimit: paging.limit,
            });
          },
          click: (motoristId) => setSelectedMotorist?.(motoristId),
        }}
        data={{
          defaultSelected: localMotoristId || urlMotoristId || '',
          list: list
            ? list.map((element, index) => {
                const {
                  _id,
                  firstName,
                  lastName,
                  email,
                  status,
                  inside,
                  plates,
                  cards,
                  uids,
                  linkedmotorist,
                  activeAndTotalCount,
                } = element;

                const credentialCount = [
                  ...plates,
                  ...uids,
                  ...cards,
                  ...(linkedmotorist?.credentials
                    ? linkedmotorist.credentials.filter(
                        ({ type }) => type === 'QRCODE',
                      )
                    : []),
                ].length;

                //anonimize email
                const anonEmail = anonimizedEmail(email);

                return {
                  _id,
                  items: {
                    component: (
                      <MotoristLine
                        data={{
                          firstName,
                          localMotoristId: _id,
                          lastName,
                          hasUnfinishedContract:
                            linkedmotorist &&
                            linkedmotorist.contracts &&
                            !!linkedmotorist.contracts.list.find(
                              ({ stopDate }) =>
                                DateTime.fromISO(stopDate) > DateTime.now(),
                            )
                              ? true
                              : false,
                          activeContracts:
                            activeAndTotalCount && activeAndTotalCount.total > 0
                              ? [
                                  activeAndTotalCount.active,
                                  activeAndTotalCount.total,
                                ]
                              : undefined,
                          credentials: credentialCount,
                          email: anonEmail,
                          status:
                            status === 'VERIFIED' ? 'VERIFIED' : 'UNVERIFIED',
                          inside,
                        }}
                      />
                    ),
                  },
                };
              })
            : undefined,
          paging: paging,
        }}
      />
    </Widget>
  );
};
